<template>
  <input
    ref="inputRef"
    :value="vModel"
    :text="vModel"
    placeholder="Location"
    class="h-10 w-full flex-1 rounded-xl bg-white p-3 py-2 transition-all duration-200 [appearance:textfield] placeholder:text-sm placeholder:font-normal placeholder:leading-5 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
    :class="[
      error
        ? 'outline-error-100 peer-hover/icon-prefix:outline-error-100 peer-hover/icon:outline-error-100 peer-hover/prefix:outline-error-100 peer-hover/suffix:outline-error-100 peer-hover:outline-error-100 '
        : 'outline-black-20 hover:outline-primary-50 active:outline-primary-120 peer-hover/icon-prefix:outline-primary-50 peer-hover/icon:outline-primary-50 peer-hover/prefix:outline-primary-50 peer-hover/suffix:outline-primary-50 peer-hover:outline-primary-50  peer-focus:outline-primary-120 peer-active:outline-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:bg-black-05 peer-disabled:outline-black-20',
      { 'border-[1.5px] border-solid border-black-20': !!vModel },
    ]"
    @place_changed="update"
  />
</template>

<script setup lang="ts">
import { Loader } from '@googlemaps/js-api-loader'
const emits = defineEmits(['update:modelValue'])
type Props = {
  modelValue: string | undefined
  error?: string
}

const props = defineProps<Props>()

const vModel = useVModel(props, 'modelValue', emits)

const update = (location: google.maps.places.PlaceResult) => {
  vModel.value = location.name
}

const runtimeConfig = useRuntimeConfig()

const inputRef = ref<HTMLInputElement | null>(null)

onMounted(async () => {
  const loader = new Loader({
    apiKey: runtimeConfig.public.GOOGLE_MAPS_API_KEY,
    version: 'weekly',
  })

  const Places = await loader.importLibrary('places')

  if (!inputRef.value) return

  const autocomplete = new Places.Autocomplete(inputRef.value)

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()
    if (place && place.geometry && place.geometry.location) {
      update(place)
    }
  })
})
</script>

<style scoped></style>
