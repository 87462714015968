<template>
  <div class="flex flex-col gap-4 xs:flex-row xs:items-center">
    <div class="flex flex-row items-center gap-4">
      <div class="w-6">
        <UiIcon name="clock" class="text-black-70" />
      </div>
      <div class="w-full">
        <UiInputDatePicker
          :model-value="new Date(start)"
          name="activity_start"
          :show-icon="false"
          :class="{ 'mr-4': allDay }"
          hide-suffix
          :start-with-placeholder="false"
          date-format="MMM dd, yyyy"
          :error="error"
          @update:model-value="input"
        />
      </div>
    </div>
    <div v-if="!allDay" class="flex w-full flex-row items-center gap-1 pl-10 xs:w-7/12 xs:pl-0">
      <div class="w-1/2">
        <UiInputSelect
          v-model="startTime"
          :items="timeOptions"
          :removable="false"
          name="activity_start_hour"
          :hide-arrow="true"
        />
      </div>
      -
      <div class="w-1/2">
        <UiInputSelect
          v-model="endTime"
          :items="endTimeOptions"
          :removable="false"
          name="activity_end_hour"
          :hide-arrow="true"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { endOfDay, getDay } from 'date-fns'

const emits = defineEmits(['update:modelValue'])

type Props = {
  start: Date | string
  end: Date | string
  error: string
  allDay: boolean
}

const props = defineProps<Props>()

const startTime = computed({
  get() {
    return { hours: new Date(props.start).getHours(), minutes: new Date(props.start).getMinutes() }
  },
  set({ hours, minutes }) {
    let end =
      new Date(props.end).getHours() < hours ||
      (new Date(props.end).getHours() === hours && new Date(props.end).getMinutes() <= minutes)
        ? new Date(new Date(props.end).setHours(hours, minutes + 60))
        : new Date(props.end)

    // If the the calculations end up on the following day, set back to the end of the day
    if (getDay(end) !== getDay(new Date(props.start))) {
      end = endOfDay(new Date(props.start))
    }

    emits('update:modelValue', { start: new Date(new Date(props.start).setHours(hours, minutes)), end })
  },
})

const endTime = computed({
  get() {
    return { hours: new Date(props.end).getHours(), minutes: new Date(props.end).getMinutes() }
  },
  set({ hours, minutes }) {
    emits('update:modelValue', {
      start: new Date(props.start),
      end: new Date(new Date(props.end).setHours(hours, minutes)),
    })
  },
})

const timeOptions = (() => {
  const result = []
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes = minutes + 15) {
      result.push({
        text: `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`,
        value: { hours, minutes },
      })
    }
  }
  return result
})()

const endTimeOptions = computed(() =>
  timeOptions
    .filter(
      (t) =>
        t.value.hours > new Date(props.start).getHours() ||
        (t.value.hours === new Date(props.start).getHours()
          ? t.value.minutes > new Date(props.start).getMinutes()
          : false)
    )
    .concat({
      text: '24:00',
      value: { hours: 23, minutes: 59 },
    })
)

const input = (date: Date) => {
  emits('update:modelValue', {
    start: new Date(new Date(date).setHours(startTime.value.hours, startTime.value.minutes)),
    end: new Date(new Date(date).setHours(endTime.value.hours, endTime.value.minutes)),
  })
}
</script>

<style scoped></style>
