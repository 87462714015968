<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-row items-center gap-4">
      <div class="w-6">
        <UiIcon name="user-group" class="text-black-70" />
      </div>
      <UiInputChips
        v-model="vModel.participants"
        name="guests"
        placeholder="Guests"
        ghost
        :error="useGetFieldErrors(vuelidate, ['participants'])"
      />
      <UiTooltip v-if="selectedLeadEmail && !vModel.participants.includes(selectedLeadEmail)" name="add-lead-email">
        <template #activator="{ onClick }">
          <UiButtonBase
            id="add-guest"
            name="add-guest"
            icon
            type="secondary"
            @click="$emit('add-lead-email'), onClick()"
          >
            <UiIcon name="small-add" />
          </UiButtonBase>
        </template>
        <template #content> Add lead e-mail </template>
      </UiTooltip>
    </div>
    <div class="flex flex-row items-center gap-4">
      <div class="w-6">
        <UiIcon name="location" class="text-black-70" />
      </div>
      <UiInputLocations v-model="vModel.location" />
    </div>
    <div class="flex flex-row items-center gap-4">
      <div class="w-6">
        <UiIcon name="videocall" class="text-black-70" />
      </div>
      <div v-if="vModel.generate_meet" class="flex flex-row items-center justify-between gap-2">
        <div class="flex flex-row items-center gap-2">
          <div class="w-6">
            <UiIcon name="check-filled" class="fill-white text-success-100" />
          </div>
          <div class="text-body-2 w-auto text-black-80">
            Video call link is generated and will be added after creating the activity.
          </div>
        </div>
        <UiButtonBase id="delete_url" icon type="secondary" class="!border-black-20" @click="removeMeeting">
          <UiIcon name="trash" class="text-black-60"></UiIcon>
        </UiButtonBase>
      </div>
      <div v-else-if="vModel.meet_url" class="flex flex-row items-center gap-2">
        <UiButtonBase
          id="join_meeting"
          type="secondary"
          @click="
            navigateTo(vModel.meet_url, {
              external: true,
              open: {
                target: '_blank',
              },
            })
          "
          >Join Google meeting
        </UiButtonBase>
        <UiButtonBase id="copy_url" icon type="secondary" class="!border-black-20" @click="copyLink">
          <UiIcon name="copy" class="text-black-60"></UiIcon>
        </UiButtonBase>
        <UiButtonBase id="delete_url" icon type="secondary" class="!border-black-20" @click="removeMeeting">
          <UiIcon name="trash" class="text-black-60"></UiIcon>
        </UiButtonBase>
      </div>
      <div v-else>
        <UiButtonBase id="join_meeting" type="secondary" @click="vModel.generate_meet = true"
          >Add video call
        </UiButtonBase>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import type { Activity } from '@/types'
import { TOURS } from '~/components/dynamic/maps'
import { useUiStore } from '~/store/ui'
import { TOURS_IDS } from '~/constants'

const emits = defineEmits(['update:modelValue', 'add-lead-email'])

const uiStore = useUiStore()

type Props = {
  modelValue: Activity
  vuelidate: Validation
  selectedLeadEmail?: string
}

const props = defineProps<Props>()

const vModel = useVModel(props, 'modelValue', emits)

onNuxtReady(() => {
  if (props.modelValue.lead_id) {
    uiStore.showTour({
      component: TOURS.ACTIVITY_MEETING_CREATION,
      id: TOURS_IDS.ACTIVITY_MEETING_CREATION,
    })
  }
})

const copyLink = () => {
  if (props.modelValue.meet_url) navigator.clipboard.writeText(props.modelValue.meet_url as string)
}

const removeMeeting = () => {
  vModel.value.meet_url = undefined
  vModel.value.generate_meet = false
}
</script>

<style scoped></style>
